import { FunctionComponent } from "react";
import styles from "./Item.module.scss";

interface Props {
  title: string;
  value: string;
}

export const Item: FunctionComponent<Props> = ({ title, value }) => {
  return (
    <div className={styles.item}>
      <div className={styles.key}>{title}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};
