import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ResultBox from "components/result-box";
import global from "global";
import request from "request";
import { useSelector } from "store/hooks";
import { calculateRisk, RiskDivision } from "utils/calculateRisk";
import { normalizeText } from "utils/normalizeText";
import transcript from "utils/transcript";
import myFont from "./font/Arial-Light.ttf";
import styles from "./result.module.scss";

Font.register({
  family: "Arial",
  src: myFont,
});

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Arial",
    padding: "10px",
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  result: {
    fontSize: "18px",
    fontWeight: 700,
  },
  bottom: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mainTitle: {
    fontSize: "18px",
    marginTop: "14px",
    marginBottom: "4px",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
});

interface RiskDecision {
  result: "Rejected" | "Approved" | "";
  text: RiskDivision | "";
  value: number;
}

const Result = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const contextApplication = useSelector((state) => state.application);
  const [riskDecision, setRiskDecision] = useState<RiskDecision>({
    result: "",
    text: "",
    value: 0,
  });

  useEffect(() => {
    if (contextApplication.data?.is_approved === null) navigate("/");
  }, [contextApplication, navigate]);

  useEffect(() => {
    if (!contextApplication?.data?.risks?.total_risk) return;

    setRiskDecision(calculateRisk(contextApplication?.data?.risks?.total_risk));
  }, [contextApplication?.data?.risks?.total_risk]);

  useEffect(() => {
    request
      .get(`/applications/${contextApplication.data?.id}/shap`)
      .then((res) => setData(res.data));
  }, [contextApplication.data?.id]);

  return (
    <div className={styles.result}>
      <div className={styles.fields}>
        <div className={styles.pdf}>
          <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
            <Pdf
              contextApplication={contextApplication}
              riskDecision={riskDecision}
            />
          </PDFViewer>
        </div>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Decision Shapley Values</div>
          {data && (
            <iframe
              style={{ width: "100%" }}
              title="shap"
              srcDoc={data}
            ></iframe>
          )}
          <div
            className={styles.gpt_text}
            dangerouslySetInnerHTML={{
              __html: String(
                contextApplication.data?.scoring_log?.gpt_explanation
                  .replaceAll("\n", "<br/>")
                  .replaceAll(
                    "\t",
                    `<span style="display: inline-block; width: 20px"></span>`
                  )
              ),
            }}
          />
        </div>
      </div>
      <div className={styles.resultBoxGroup}>
        <ResultBox />
      </div>
    </div>
  );
};

const Pdf = ({
  contextApplication,
  riskDecision,
}: {
  contextApplication: global.contextApplication;
  riskDecision: RiskDecision;
}) => {
  return (
    <Document>
      {contextApplication.data?.scoring_log && (
        <>
          <Page style={pdfStyles.page}>
            <View style={pdfStyles.heading}>
              <Text>Scoring report</Text>
            </View>
            <View style={pdfStyles.section}>
              <Text style={pdfStyles.text}>
                Date/Time:{" "}
                {new Date(
                  contextApplication.data?.created_at ?? ""
                ).toLocaleString()}
              </Text>
              <Text style={pdfStyles.text}>
                Application: {contextApplication.data?.id}
              </Text>
              <Text style={pdfStyles.mainTitle}>Customer data</Text>
              <View style={pdfStyles.table}>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Customer ID</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.customerId}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Application ID</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.applicationId}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Amount</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.loanAmount} JOD</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Interest rate</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.interestRate}%</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Branch</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.branch}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Gender</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.gender(
                        contextApplication.data?.scoring_log?.gender
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Family status</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.familyStatus(
                        contextApplication.data?.scoring_log?.familyStatus
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Employment sphere</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.employment_spheres(
                        contextApplication.data?.scoring_log?.employmentSphere
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Loan duration</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.loanDuration}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Collateral</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.collateral(
                        contextApplication.data?.scoring_log?.collateral
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Co borrower</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.coBorrower(
                        contextApplication.data?.scoring_log?.collateral
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Type of product</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.productType}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Employment level</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.employment_levels(
                        contextApplication.data?.scoring_log?.employmentLevel
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Age</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.age}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Monthly income</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.monthlyIncome} JOD
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Work experience</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.workExperience}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Dependents</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.dependents}
                    </Text>
                  </View>
                </View>
                {contextApplication.data?.scoring_log?.is_approved !== null && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>Scoring prediction</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {contextApplication.data?.scoring_log?.prediction}%
                      </Text>
                    </View>
                  </View>
                )}
              </View>
              <Text style={pdfStyles.mainTitle}>Product data</Text>
              <View style={pdfStyles.table}>
                {contextApplication?.data?.product === "Housing" && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>Loan type</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {contextApplication.data.scoring_log?.loan_type}
                      </Text>
                    </View>
                  </View>
                )}
                {(contextApplication?.data?.product === "Housing" ||
                  contextApplication?.data?.product === "Land purchase" ||
                  contextApplication?.data?.product === "Mortgage" ||
                  contextApplication?.data?.product === "Auto") && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>Property value</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {contextApplication.data.scoring_log?.property_value}
                      </Text>
                    </View>
                  </View>
                )}
                {(contextApplication?.data?.product === "Housing" ||
                  contextApplication?.data?.product === "Auto") && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>Property age at loan maturity</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {
                          contextApplication?.data?.scoring_log
                            ?.property_age_at_end
                        }
                      </Text>
                    </View>
                  </View>
                )}
                {(contextApplication?.data?.product === "Housing" ||
                  contextApplication?.data?.product === "Land purchase" ||
                  contextApplication?.data?.product === "Mortgage") && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>Property outside boundaries of regulation</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {
                          contextApplication?.data?.scoring_log
                            ?.property_outside_regulations
                        }
                      </Text>
                    </View>
                  </View>
                )}
                {contextApplication?.data?.product === "Auto" && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>Car evaluation score</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {
                          contextApplication?.data?.scoring_log
                            ?.car_evaluation_score
                        }
                      </Text>
                    </View>
                  </View>
                )}
                {contextApplication?.data?.product === "Credit Card" && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>Credit card limit</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {
                          contextApplication?.data?.scoring_log
                            ?.credit_card_limit
                        }
                      </Text>
                    </View>
                  </View>
                )}
                {contextApplication?.data?.product === "Personal" && (
                  <>
                    <View style={pdfStyles.tr}>
                      <View style={pdfStyles.td}>
                        <Text>Target market list</Text>
                      </View>
                      <View style={pdfStyles.td}>
                        <Text>
                          {
                            contextApplication?.data?.scoring_log
                              ?.target_market_list
                          }
                        </Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tr}>
                      <View style={pdfStyles.td}>
                        <Text>Length of service (in months)</Text>
                      </View>
                      <View style={pdfStyles.td}>
                        <Text>
                          {
                            contextApplication?.data?.scoring_log
                              ?.length_of_service
                          }
                        </Text>
                      </View>
                    </View>
                    <View style={pdfStyles.tr}>
                      <View style={pdfStyles.td}>
                        <Text>Against collateral</Text>
                      </View>
                      <View style={pdfStyles.td}>
                        <Text>
                          {contextApplication?.data?.scoring_log?.collateral}
                        </Text>
                      </View>
                    </View>
                  </>
                )}
                {(contextApplication?.data?.product === "Housing" ||
                  contextApplication?.data?.product === "Land purchase" ||
                  contextApplication?.data?.product === "Mortgage" ||
                  contextApplication?.data?.product === "Auto") && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>LTV</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {Number(
                          contextApplication?.data?.scoring_log?.ltv * 100
                        )?.toFixed(2)}
                        %
                      </Text>
                    </View>
                  </View>
                )}
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>DBR</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {Number(
                        contextApplication?.data?.scoring_log?.dbr * 100
                      ).toFixed(2)}
                      %
                    </Text>
                  </View>
                </View>
              </View>
              <Text style={pdfStyles.mainTitle}>Verification</Text>
              <View style={pdfStyles.table}>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>CBJ Black list / Returned Cheques</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication?.data?.scoring_log.cbj_black_list}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Internal black list</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {
                        contextApplication?.data?.scoring_log
                          ?.internal_black_list
                      }
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Inspector / Financial legal cases</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {
                        contextApplication?.data?.scoring_log
                          ?.inspector_legal_cases
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <Text style={pdfStyles.mainTitle}>Risks</Text>
              <View style={pdfStyles.table}>
                {Object.entries(contextApplication?.data?.risks).map(
                  ([key, value]) => (
                    <View key={key} style={pdfStyles.tr}>
                      <View style={pdfStyles.td}>
                        <Text>{`${normalizeText(key)}`}</Text>
                      </View>
                      <View style={pdfStyles.td}>
                        <Text>{`${value}%`}</Text>
                      </View>
                    </View>
                  )
                )}
              </View>
              <View style={pdfStyles.bottomContainer}>
                <View style={pdfStyles.bottom}>
                  <Text style={pdfStyles.result}>
                    Result:{" "}
                    {contextApplication.data?.is_approved
                      ? `Approved ${contextApplication?.data?.scoring_log?.prediction}%`
                      : `Rejected ${contextApplication?.data?.scoring_log?.prediction}%`}
                  </Text>
                  <Text style={pdfStyles.result}>
                    Threshold:{" "}
                    {Number(contextApplication.data?.scoring_log?.threshold)}%
                  </Text>
                </View>
                <View style={pdfStyles.bottom}>
                  <Text>
                    Risk factors: {riskDecision.text} ({riskDecision.value}%){" "}
                    {riskDecision.result}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        </>
      )}
    </Document>
  );
};

export default React.memo(Result, () => true);
