import { AxiosError } from "axios";
import React from "react";

import Button from "components/button";
import RangeBox from "components/range-box";
import { toast } from "react-toastify";
import request from "request";
import styles from "./settings.module.scss";

const Settings = () => {
  const [updatePending, setUpdatePending] = React.useState(false);
  const [loadPending, setLoadPending] = React.useState(true);

  const [scoringThreshold, setScoringThreshold] = React.useState(0);
  const [CPI, setCPI] = React.useState("");
  const [inflation_rate, setInflation_rate] = React.useState("");
  const [manufacturing_PMI, setManufacturing_PMI] = React.useState("");

  const handleSetSettings = () => {
    setUpdatePending(true);
    request
      .put("/admin/constant_values", {
        threshold: scoringThreshold / 100,
        CPI: Number(CPI),
        inflation_rate: Number(inflation_rate),
        manufacturing_PMI: Number(manufacturing_PMI),
      })
      .then(() => toast.success("Saved!"))
      .catch((err: AxiosError) => {
        toast.info((err.response?.data as any).detail);
      })
      .finally(() => setUpdatePending(false));
  };

  React.useEffect(() => {
    setLoadPending(true);
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((data) => {
        setScoringThreshold(Math.round(data.threshold * 100));
        setCPI(data.CPI);
        setInflation_rate(data.inflation_rate);
        setManufacturing_PMI(data.manufacturing_PMI);
      })
      .finally(() => setLoadPending(false));
  }, []);

  if (loadPending) return <></>;
  return (
    <form className={styles.settings}>
      <div className={styles.fields}>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Scoring Threshold (%)</div>
          <RangeBox value={scoringThreshold} setValue={setScoringThreshold} />
        </div>
        <div className={styles.footerBox}>
          <Button pending={updatePending} onClick={handleSetSettings}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Settings;
