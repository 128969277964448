
export type RiskDivision =
  | "Very high risk"
  | "High risk"
  | "Medium risk"
  | "Low risk"
  | "No risk";

export function calculateRisk(totalRisk: number): {
    result: "Rejected" | "Approved";
    text: RiskDivision;
    value: number;
  } {
    if (totalRisk >= 75) {
      return { result: "Rejected", text: "Very high risk", value: totalRisk };
    } else if (totalRisk >= 50) {
      return { result: "Rejected", text: "High risk", value: totalRisk };
    } else if (totalRisk >= 35) {
      return { result: "Approved", text: "Medium risk", value: totalRisk };
    } else if (totalRisk >= 25) {
      return { result: "Approved", text: "Low risk", value: totalRisk };
    }
  
    return { result: "Approved", text: "No risk", value: totalRisk };
  }