import { useLayoutEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { AxiosError } from "axios";
import Loading from "components/loading";
import Navbar from "components/navbar";
import { toast } from "react-toastify";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import styles from "./application.module.scss";

const ApplicationLayout = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const contextApplication = useSelector((state) => state.application);

  const [ocrPending, setOcrPending] = useState(false);

  const handleRunOCR = async (fileCRIF: File | null) => {
    if (!fileCRIF) return toast.error("Please upload CRIF file");
    setOcrPending(true);
    const fd = new FormData();
    fd.append("pdf", fileCRIF);

    request({
      url: `/applications/${id}/ocr`,
      method: "POST",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => res.data)
      .then(() =>
        dispatch.application.FETCH_APPLICATION_BY_ID_WITHOUT_PENDING(id)
      )
      .catch((res: AxiosError) => {
        if (res.response?.status === 422)
          toast.error("Please upload CRIF file");
      })
      .finally(() => setOcrPending(false));
  };

  useLayoutEffect(() => {
    dispatch.application.FETCH_APPLICATION_BY_ID(id);
  }, [dispatch.application, id]);

  return (
    <div className={styles.applicationLayout} id="application-layout">
      {contextApplication.pending && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      {!contextApplication.pending && (
        <div className={styles.container}>
          <div className={styles.navbar}>
            <Navbar
              resultBlocked={contextApplication.data?.scoring_log === null}
            />
          </div>
          <div className={styles.content}>
            <Outlet context={{ handleRunOCR, ocrPending }} />
          </div>
        </div>
      )}
    </div>
  );
};

type ContextType = {
  // new_financial_legal_case: {
  //   key: string;
  //   value: string;
  // };
  // set_new_financial_legal_case: React.Dispatch<
  //   React.SetStateAction<{
  //     key: string;
  //     value: string;
  //   }>
  // >;
  // bad_credit_reputation_case: {
  //   key: string;
  //   value: string;
  // };
  // set_bad_credit_reputation_case: React.Dispatch<
  //   React.SetStateAction<{
  //     key: string;
  //     value: string;
  //   }>
  // >;
  // hasLegalCase: boolean;
  // setHasLegalCase: React.Dispatch<React.SetStateAction<boolean>>;
  // legalCaseDate: string;
  // setLegalCaseDate: React.Dispatch<React.SetStateAction<string>>;
  // handleRunLegalCase: () => Promise<void>;
  ocrPending: boolean;
  handleRunOCR: (fileCRIF: File | null) => void;
};

export const useOCR = () => {
  return useOutletContext<ContextType>();
};

export default ApplicationLayout;
