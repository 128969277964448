import { isAxiosError } from "axios";
import { AutoForm } from "components/AutoForm/AutoForm";
import Button from "components/button";
import { CreditCardForm } from "components/CreditCardForm/CreditCardForm";
import { HousingForm } from "components/HousingForm/HousingForm";
import Input from "components/input";
import { Item } from "components/Item/Item";
import { ItemContainer } from "components/ItemContainer/ItemContainer";
import { LandForm } from "components/LandForm/LandForm";
import Loading from "components/loading";
import { PersonalForm } from "components/PersonalForm/PersonalForm";
import ResultBox from "components/result-box";
import global from "global";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import {
  branch,
  coBorrowers,
  collaterals,
  educations,
  employment_levels,
  employment_spheres,
  family_statuses,
  genders,
  productType,
  states,
  yesOrNo,
} from "utils/constants";
import { normalizeText } from "utils/normalizeText";
import prettierNumber from "utils/prettier-number";
import transcript from "utils/transcript";
import styles from "./scoring.module.scss";

const initialValidationState = {
  age: "",
  monthlyIncome: "",
  typeOfProduct: "",
  duration_at_disbursement_months: "",
};

export interface ScoringForm {
  branch: string;
  gender: (typeof genders)[number];
  familyStatus: (typeof family_statuses)[number];
  employmentSphere: (typeof employment_spheres)[number] | "";
  education: (typeof educations)[number] | "";
  collateral: (typeof yesOrNo)[number] | "";
  coBorrower: (typeof yesOrNo)[number] | "";
  productType: string;
  employmentLevel: (typeof employment_levels)[number] | "";
  loanDuration: string;
  age: string;
  monthlyIncome: string;
  workExperience: string;
  dependents: string;
  cbj_black_list: (typeof states)[number] | "";
  internal_black_list: (typeof states)[number] | "";
  inspector_legal_cases: (typeof states)[number] | "";
}
const Scoring = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const contextApplication = useSelector((state) => state.application);

  const dispatch = useDispatch();
  const [macrodata, setMacrodata] = useState<global.macrodata>();

  const [pending, setPending] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [validationError, setValidationError] = useState(
    initialValidationState
  );

  const [form, setForm] = useState<ScoringForm>({
    branch: "",
    gender: "",
    familyStatus: "",
    employmentSphere: "",
    education: "",
    collateral: "",
    coBorrower: "",
    productType: "",
    employmentLevel: "",
    loanDuration: "",
    age: "",
    monthlyIncome: "",
    workExperience: "",
    dependents: "",
    cbj_black_list: "",
    internal_black_list: "",
    inspector_legal_cases: "",
  });

  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;

    setPending(true);

    request
      .post(`/applications/${id}/scoring`, {
        ...form,
        branch: form.branch,
        gender: transcript.gender(form.gender),
        familyStatus: transcript.familyStatus(form.familyStatus),
        employmentSphere: transcript.employment_spheres(form.employmentSphere),
        collateral: transcript.collateral(form.collateral),
        coBorrower: transcript.coBorrower(form.coBorrower),
        productType: form.productType,
        employmentLevel: transcript.employment_levels(form.employmentLevel),
        education: transcript.education(form.education),
        loanDuration: Number(form.loanDuration),
        interestRate: Number(contextApplication.data?.interestRate),
        age: Number(form.age),
        monthlyIncome: Number(form.monthlyIncome),
        workExperience: Number(form.workExperience),
        dependents: Number(form.dependents),
        grantedLimitAM: Number(contextApplication.data?.loanAmount),
        cbj_black_list: form.cbj_black_list,
        internal_black_list: form.internal_black_list,
        inspector_legal_cases: form.inspector_legal_cases,
        length_of_service:
          "length_of_service" in form
            ? Number(form.length_of_service)
            : undefined,
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(
          () => dispatch.application.FETCH_APPLICATION_BY_ID(id),
          1000
        );
      })
      .catch((e: unknown) => {
        if (!isAxiosError(e)) return;

        if (e.response?.status === 409) {
          toast.error(e.response.data.detail);
        } else if (e.response?.status === 422) {
          toast.error(e?.response?.data?.detail[0]?.msg);
        }
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    if (user.role === "expert") return;
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, [user.role]);

  if (user.role !== "expert" && !macrodata) return <></>;
  return (
    <div className={styles.home}>
      <div className={styles.fields}>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Credit data</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>Customer ID</div>
              <div className={styles.value}>
                {contextApplication.data?.customerId}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Application ID</div>
              <div className={styles.value}>
                {contextApplication.data?.applicationId}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Amount</div>
              <div className={styles.value}>
                {prettierNumber(contextApplication.data?.loanAmount ?? 0)} JOD
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Interest rate</div>
              <div className={styles.value}>
                {contextApplication.data?.interestRate}%
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Product</div>
              <div className={styles.value}>
                {contextApplication.data?.product}
              </div>
            </div>
            {(user.role !== "expert" ||
              contextApplication.data?.scoring_log?.threshold) && (
              <div className={styles.item}>
                <div className={styles.key}>Scoring threshold</div>
                <div className={styles.value}>
                  {contextApplication.data?.scoring_log?.threshold
                    ? Number(contextApplication.data?.scoring_log?.threshold)
                    : Number(macrodata?.threshold) * 100}{" "}
                  %
                </div>
              </div>
            )}
          </div>
        </div>
        {!!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Customer data</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Branch</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.branch}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Gender</div>
                  <div className={styles.value}>
                    {transcript.gender(
                      contextApplication.data.scoring_log.gender
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Family status</div>
                  <div className={styles.value}>
                    {transcript.familyStatus(
                      contextApplication.data.scoring_log.familyStatus
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Employment sphere</div>
                  <div className={styles.value}>
                    {transcript.employment_spheres(
                      contextApplication.data.scoring_log.employmentSphere
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Collateral</div>
                  <div className={styles.value}>
                    {transcript.collateral(
                      contextApplication.data.scoring_log.collateral
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Co borrower</div>
                  <div className={styles.value}>
                    {transcript.coBorrower(
                      contextApplication.data.scoring_log.coBorrower
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Type of product</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.productType}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Employment level</div>
                  <div className={styles.value}>
                    {transcript.employment_levels(
                      contextApplication.data.scoring_log.employmentLevel
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Education</div>
                  <div className={styles.value}>
                    {transcript.education(
                      contextApplication.data.scoring_log.education
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Loan duration</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.loanDuration}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Age</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.age}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Monthly income</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.monthlyIncome} JOD
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Work experience</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.workExperience}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Dependents</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.dependents}
                  </div>
                </div>
                {contextApplication.data?.scoring_log?.is_approved !== null && (
                  <div className={styles.item}>
                    <div className={styles.key}>Scoring prediction</div>
                    <div className={styles.value}>
                      {contextApplication.data?.scoring_log?.prediction}%
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ItemContainer title={"Product data"}>
              <>
                {contextApplication?.data?.product === "Housing" && (
                  <Item
                    title="Loan type"
                    value={contextApplication?.data?.scoring_log?.loan_type}
                  />
                )}
                {(contextApplication?.data?.product === "Housing" ||
                  contextApplication?.data?.product === "Land purchase" ||
                  contextApplication?.data?.product === "Mortgage" ||
                  contextApplication?.data?.product === "Auto") && (
                  <Item
                    title="Property value"
                    value={
                      contextApplication?.data?.scoring_log?.property_value
                    }
                  />
                )}
                {(contextApplication?.data?.product === "Housing" ||
                  contextApplication?.data?.product === "Auto") && (
                  <Item
                    title="Property age at loan maturity"
                    value={
                      contextApplication?.data?.scoring_log?.property_age_at_end
                    }
                  />
                )}
                {(contextApplication?.data?.product === "Housing" ||
                  contextApplication?.data?.product === "Land purchase" ||
                  contextApplication?.data?.product === "Mortgage") && (
                  <Item
                    title="Property outside boundaries of regulation"
                    value={
                      contextApplication?.data?.scoring_log
                        ?.property_outside_regulations
                    }
                  />
                )}
                {contextApplication?.data?.product === "Auto" && (
                  <Item
                    title="Car evaluation score"
                    value={
                      contextApplication?.data?.scoring_log
                        ?.car_evaluation_score
                    }
                  />
                )}
                {contextApplication?.data?.product === "Credit Card" && (
                  <Item
                    title="Credit card limit"
                    value={
                      contextApplication?.data?.scoring_log?.credit_card_limit
                    }
                  />
                )}
                {contextApplication?.data?.product === "Personal" && (
                  <>
                    <Item
                      title="Target market list"
                      value={
                        contextApplication?.data?.scoring_log
                          ?.target_market_list
                      }
                    />
                    <Item
                      title="Length of service (in months)"
                      value={
                        contextApplication?.data?.scoring_log?.length_of_service
                      }
                    />
                    <Item
                      title="Against collateral"
                      value={contextApplication?.data?.scoring_log?.collateral}
                    />
                  </>
                )}
                {(contextApplication?.data?.product === "Housing" ||
                  contextApplication?.data?.product === "Land purchase" ||
                  contextApplication?.data?.product === "Mortgage" ||
                  contextApplication?.data?.product === "Auto") && (
                  <Item
                    title="LTV"
                    value={`${Number(
                      contextApplication?.data?.scoring_log?.ltv * 100
                    )?.toFixed(2)}%`}
                  />
                )}
                <Item
                  title="DBR"
                  value={`${Number(
                    contextApplication?.data?.scoring_log?.dbr * 100
                  ).toFixed(2)} %`}
                />
              </>
            </ItemContainer>
            <ItemContainer title="Verification">
              <>
                <Item
                  title="CBJ Black list / Returned Cheques"
                  value={contextApplication?.data?.scoring_log.cbj_black_list}
                />
                <Item
                  title="Internal black list"
                  value={
                    contextApplication?.data?.scoring_log?.internal_black_list
                  }
                />
                <Item
                  title="Inspector / Financial legal cases"
                  value={
                    contextApplication?.data?.scoring_log?.inspector_legal_cases
                  }
                />
              </>
            </ItemContainer>
            <ItemContainer title="Risks">
              <>
                {contextApplication?.data?.risks !== null &&
                  Object.entries(contextApplication?.data?.risks).map(
                    ([key, value]) => (
                      <Item
                        key={key}
                        title={normalizeText(key)}
                        value={`${value}%`}
                      />
                    )
                  )}
              </>
            </ItemContainer>
          </>
        )}
        {!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.fieldGroup}>
              <div className={styles.fieldTitle}>Customer data</div>
              <div className={styles.fieldList}>
                <Input
                  hasFilterText
                  title="Branch"
                  id="branch"
                  value={form.branch}
                  type="select"
                  isArabic
                  options={branch}
                  onSelect={(option) => setForm({ ...form, branch: option })}
                />
                <Input
                  title="Gender"
                  id="gender"
                  value={form.gender}
                  type="select"
                  options={genders}
                  onSelect={(option) => setForm({ ...form, gender: option })}
                />
                <Input
                  title="Family status"
                  id="familyStatus"
                  value={form.familyStatus}
                  type="select"
                  options={family_statuses}
                  onSelect={(option) =>
                    setForm({ ...form, familyStatus: option })
                  }
                />
                <Input
                  title="Employment sphere"
                  id="employmentSphere"
                  value={form.employmentSphere}
                  type="select"
                  options={employment_spheres}
                  onSelect={(option) =>
                    setForm({
                      ...form,
                      employmentSphere:
                        option as (typeof employment_spheres)[number],
                    })
                  }
                />
                <Input
                  title="Education"
                  id="education"
                  value={form.education}
                  type="select"
                  options={educations}
                  onSelect={(option) =>
                    setForm({
                      ...form,
                      education: option as (typeof educations)[number],
                    })
                  }
                />
                <Input
                  title="Collateral"
                  id="collateral"
                  value={form.collateral}
                  type="select"
                  options={collaterals}
                  onSelect={(option) =>
                    setForm({
                      ...form,
                      collateral: option as (typeof yesOrNo)[number],
                    })
                  }
                />
                <Input
                  title="Co borrower"
                  id="coBorrower"
                  value={form.coBorrower}
                  type="select"
                  options={coBorrowers}
                  onSelect={(option) =>
                    setForm({
                      ...form,
                      coBorrower: option as (typeof yesOrNo)[number],
                    })
                  }
                />
                <Input
                  title="Type of product"
                  id="type_of_product"
                  value={form.productType}
                  type="select"
                  options={productType}
                  isArabic
                  hasFilterText
                  error={validationError.typeOfProduct}
                  onSelect={(option) =>
                    setForm({ ...form, productType: option })
                  }
                />
                <Input
                  hasFilterText
                  title="Employment level"
                  id="employmentLevel"
                  value={form.employmentLevel}
                  type="select"
                  options={employment_levels}
                  onSelect={(option) =>
                    setForm({
                      ...form,
                      employmentLevel:
                        option as (typeof employment_levels)[number],
                    })
                  }
                />
                <Input
                  title="Loan duration"
                  value={form.loanDuration}
                  type="float"
                  onInput={(value) => setForm({ ...form, loanDuration: value })}
                  step={0.1}
                  min={1}
                  max={300}
                  error={validationError.duration_at_disbursement_months}
                  placeholder={`min: 0  max: ${300}`}
                />
                <Input
                  title="Age"
                  value={form.age}
                  type="integer"
                  onInput={(value) => setForm({ ...form, age: value })}
                  step={1}
                  min={18}
                  max={80}
                  error={validationError.age}
                  placeholder={`min: 18  max: ${80}`}
                />
                <Input
                  title="Monthly income"
                  value={form.monthlyIncome}
                  type="float"
                  onInput={(value) =>
                    setForm({ ...form, monthlyIncome: value })
                  }
                  step={100}
                  min={0}
                  max={30000}
                  error={validationError.monthlyIncome}
                  placeholder={`min: 0 max: 30000`}
                />
                <Input
                  title="Work experience"
                  value={form.workExperience}
                  type="float"
                  onInput={(value) =>
                    setForm({ ...form, workExperience: value })
                  }
                  step={1}
                  min={0}
                  max={50}
                  placeholder={`min: 0  max: 50`}
                />
                <Input
                  title="Dependants"
                  value={form.dependents}
                  type="integer"
                  onInput={(value) => setForm({ ...form, dependents: value })}
                  step={1}
                  min={0}
                  max={40}
                  placeholder={`min: 0  max: ${40}`}
                />
              </div>
              {/* Product data */}
              <div className={styles.fieldTitle}>Product data</div>
              {contextApplication.data?.product === "Housing" && (
                <HousingForm
                  form={form as HousingForm}
                  setForm={setForm as Dispatch<SetStateAction<HousingForm>>}
                  setFormValid={setFormValid}
                />
              )}
              {(contextApplication.data?.product === "Land purchase" ||
                contextApplication.data?.product === "Mortgage") && (
                <LandForm
                  form={form as LandForm}
                  setForm={setForm as Dispatch<SetStateAction<LandForm>>}
                  setFormValid={setFormValid}
                />
              )}
              {contextApplication?.data?.product === "Auto" && (
                <AutoForm
                  form={form as AutoForm}
                  setForm={setForm as Dispatch<SetStateAction<AutoForm>>}
                  setFormValid={setFormValid}
                />
              )}
              {contextApplication?.data?.product === "Credit Card" && (
                <CreditCardForm
                  form={form as CreditCardForm}
                  setForm={setForm as Dispatch<SetStateAction<CreditCardForm>>}
                  setFormValid={setFormValid}
                />
              )}
              {contextApplication?.data?.product === "Personal" && (
                <PersonalForm
                  form={form as PersonalForm}
                  setForm={setForm as Dispatch<SetStateAction<PersonalForm>>}
                  setFormValid={setFormValid}
                />
              )}
              {/* Verification */}
              <div className={styles.fieldTitle}>Verification</div>
              <div className={styles.fieldList}>
                <Input
                  title="CBJ Black list / Returned Cheques"
                  id="cbj_black_list"
                  value={form.cbj_black_list}
                  type="select"
                  options={states}
                  onSelect={(option) =>
                    setForm({
                      ...form,
                      cbj_black_list: option as (typeof states)[number],
                    })
                  }
                />
                <Input
                  title="Internal Black list"
                  id="internal_black_list"
                  value={form.internal_black_list}
                  type="select"
                  options={states}
                  onSelect={(option) =>
                    setForm({
                      ...form,
                      internal_black_list: option as (typeof states)[number],
                    })
                  }
                />
                <Input
                  title="Inspector / Financial legal cases"
                  id="inspector_financial_legal_cases"
                  value={form.inspector_legal_cases}
                  type="select"
                  options={states}
                  onSelect={(option) =>
                    setForm({
                      ...form,
                      inspector_legal_cases: option as (typeof states)[number],
                    })
                  }
                />
              </div>
            </div>
            <div className={styles.footerBox}>
              <div className={styles.buttonGroup}>
                {formValid && (
                  <Button pending={pending} onClick={handleCalculate}>
                    Calculate
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.result}>
        {pending && (
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        )}
        {!pending && (
          <div className={styles.result}>
            <div className={styles.resultBox}>
              <ResultBox />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Scoring;
