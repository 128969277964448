import Input from "components/input";
import { ScoringForm } from "pages/scoring";
import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react";
import { loanTypeHousing, yesOrNo } from "utils/constants";
import styles from "./HousingForm.module.scss";

export interface HousingForm extends ScoringForm {
  loan_type: string;
  property_value: string;
  property_age_at_end: string;
  property_outside_regulations: string;
}

interface Props {
  form: HousingForm;
  setForm: Dispatch<SetStateAction<HousingForm>>;
  setFormValid: Dispatch<SetStateAction<boolean>>;
}

export const HousingForm: FunctionComponent<Props> = ({
  form,
  setForm,
  setFormValid,
}) => {
  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      loan_type: "",
      property_value: "",
      property_age_at_end: "",
      property_outside_regulations: "",
    }));
  }, []);

  useEffect(() => {
    setFormValid(
      form.branch !== "" &&
        form.gender !== "" &&
        form.familyStatus !== "" &&
        form.employmentSphere !== "" &&
        form.education !== "" &&
        form.collateral !== "" &&
        form.coBorrower !== "" &&
        form.productType !== "" &&
        form.employmentLevel !== "" &&
        form.loanDuration !== "" &&
        form.age !== "" &&
        form.monthlyIncome !== "" &&
        form.workExperience !== "" &&
        form.dependents !== "" &&
        form.cbj_black_list !== "" &&
        form.internal_black_list !== "" &&
        form.inspector_legal_cases !== "" &&
        "loan_type" in form &&
        form.loan_type !== "" &&
        "property_value" in form &&
        form.property_value !== "" &&
        "property_age_at_end" in form &&
        form.property_age_at_end !== "" &&
        "property_outside_regulations" in form &&
        form.property_outside_regulations !== ""
    );
  }, [form]);

  return (
    <div className={styles.fieldList}>
      <Input
        title="Loan type"
        id="loan_type"
        value={form.loan_type}
        type="select"
        options={loanTypeHousing}
        // onSelect={(option) => setForm({ ...form, loan_type: option })}
        onSelect={(option) =>
          setForm((prev) => ({ ...prev, loan_type: option }))
        }
      />
      <Input
        title="Property value"
        id="property_value"
        value={form.property_value}
        type="integer"
        min={0}
        max={100000000}
        placeholder="min: 0 max: 100000000"
        onInput={(value) => setForm({ ...form, property_value: value })}
      />
      <Input
        title="Property age at loan maturity"
        id="property_age"
        value={form.property_age_at_end}
        type="integer"
        min={0}
        max={100}
        placeholder="min: 0 max: 100"
        onInput={(value) => setForm({ ...form, property_age_at_end: value })}
      />
      <Input
        title="Property outside boundaries of regulation"
        id="property_outside"
        value={form.property_outside_regulations}
        type="select"
        options={yesOrNo}
        onSelect={(option) =>
          setForm({ ...form, property_outside_regulations: option })
        }
      />
    </div>
  );
};
