export function normalizeText(key: string): string {
  if(key === "land_dbr"){
    return "Land DBR"
  }else if(key === "land_ltv") {
    return "Land LTV"
  }else if(key === "cbj_black_list"){
    return "CBJ Black list"
  }else if (key === "housing_buyout_ltv") {
    return "Housing buyout LTV"
  }else if (key === "housing_dbr") {
    return "Housing DBR"
  }

    const result = key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
    return result;
  }