import Input from "components/input";
import { ScoringForm } from "pages/scoring";
import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react";
import styles from "./CreditCardForm.module.scss";

export interface CreditCardForm extends ScoringForm {
  credit_card_limit: string;
}

interface Props {
  form: CreditCardForm;
  setForm: Dispatch<SetStateAction<CreditCardForm>>;
  setFormValid: Dispatch<SetStateAction<boolean>>;
}

export const CreditCardForm: FunctionComponent<Props> = ({
  form,
  setForm,
  setFormValid,
}) => {
  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      credit_card_limit: "",
    }));
  }, []);

  useEffect(() => {
    setFormValid(
      form.branch !== "" &&
        form.gender !== "" &&
        form.familyStatus !== "" &&
        form.employmentSphere !== "" &&
        form.education !== "" &&
        form.collateral !== "" &&
        form.coBorrower !== "" &&
        form.productType !== "" &&
        form.employmentLevel !== "" &&
        form.loanDuration !== "" &&
        form.age !== "" &&
        form.monthlyIncome !== "" &&
        form.workExperience !== "" &&
        form.dependents !== "" &&
        form.cbj_black_list !== "" &&
        form.internal_black_list !== "" &&
        form.inspector_legal_cases !== "" &&
        "credit_card_limit" in form &&
        form.credit_card_limit !== ""
    );
  }, [form]);

  return (
    <div className={styles.fieldList}>
      <Input
        title="Credit card limit"
        id="credit_card_limit"
        value={form.credit_card_limit}
        type="integer"
        min={0}
        max={100000000}
        placeholder="min: 0 max: 100000000"
        onInput={(value) => setForm({ ...form, credit_card_limit: value })}
      />
    </div>
  );
};
