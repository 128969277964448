export const carEvaulation =  ["Accepted", "Non accepted"] as const

export const loanTypeHousing = ["New", "Buyout"] as const;

export const yesOrNo = ["Yes", "No"] as const;

export const states =  ["Active", "Inactive"] as const;

export const productEnum =  ["Housing", "Land purchase", "Mortgage", "Auto", "Credit Card", "Personal"] as const

export const locations = [
  "JORDAN",
  "EGYPT",
  "PAKISTAN",
  "CHINA",
  "UNITED ARAB EMIRATES",
  "PHILIPPINES",
  "SYRIAN ARAB REPUBLIC",
  "INDIA",
  "IRELAND",
  "IRAQ",
  "LEBANON",
  "SRI LANKA",
  "SAUDI ARABIA",
  "MOROCCO",
  "PALESTINIAN",
  "UNITED KINGDOM",
  "BANGLADESH",
  "SUDAN",
  "MYANMAR",
  "ALGERIA",
  "IRAN",
  "UNITED STATES",
  "GERMANY",
  "AFGHANISTAN",
  "SOUTH AFRICA",
  "MALAYSIA",
  "TUNISIA",
  "ROMANIA",
  "UZBEKISTAN",
  "ETHIOPIA",
  "KYRGYZSTAN",
  "FRANCE",
  "NAMIBIA",
  "OMAN",
  "KENYA",
  "ZIMBABWE",
  "NEPAL",
  "MACEDONIA",
  "VENEZUELA",
  "UKRAINE",
  "DOMINICA",
  "BAHRAIN",
  "CANADA",
  "AUSTRALIA",
  "TURKEY",
  "NORWAY",
  "SERBIA",
  "ITALY",
  "NETHERLANDS",
  "BELGIUM",
  "BELARUS",
  "NIGERIA",
  "COMOROS",
  "YEMEN",
  "MEXICO",
  "INDONESIA",
  "TANZANIA UNITED REP",
  "ARGENTINA",
  "POLAND",
  "SWITZERLAND",
  "MAURITIUS",
  "BRAZIL",
  "JAPAN",
  "RUSSIA FEDERATION",
  "KOREA, SOUTH",
  "CROATIA",
  "NEW ZEALAND",
  "SPAIN",
  "MALTA",
  "MAURITANIA",
  "CAMEROON UNITED REP",
  "SOMALIA",
  "THAILAND",
  "GREECE",
  "SAN MARINO",
  "SWEDEN",
  "GAMBIA",
  "BULGARIA",
  "ST KITIS-NEVIS-ANGUI",
  "QATAR",
];

export const branch = [
  "سحاب",
  "الرئيسي",
  "ماركا",
  "ش. عبدااله غوشه",
  "المفرق",
  "خلدا",
  "تاج مول",
  "مأدبا",
  "مكة مول",
  "عبدون",
  "العقبه",
  "طبربور",
  "الشرق الاوسط",
  "شارع مكه",
  "اربد",
  "الصويفيه",
  "السلط",
  "سيتي مول",
  "الزرقاء",
  "معان",
  "فرع باب المدينة مول",
  "الكرك",
  "المنطقه الحره",
  "جرش",
  "الطفيله",
  "جبل عمان",
  "جبل الحسين",
  "وادي صقرة",
  "بوابه السلط",
  "الزرقاء الجديده",
  "الرصيفه",
  "ارابيلا مول",
  "مؤته",
  "ش. وصفي التل",
  "جامعه البلقاء",
  "الهاشمي الشمالي",
  "مرج الحمام",
  "الجبيهه",
  "شارع الحريه",
  "الرمثا",
  "البيادر",
  "الفحيص",
  "دير علا",
  "ضاحيه الياسمين",
  "بوليفارد العبدلي",
  "سوق ام اذينه",
  "وسط البلد",
  "ش. ابن خلدون",
  "الشركات الكبرى",
];

export const branchEN = [
  "Balqa University",
  "Jerash",
  "Ibn Khaldoun St.",
  "Madaba",
  "Aqaba",
  "Karak",
  "Freedom street",
  "Salt Gate",
  "Tafila",
  "Mecca Mall",
  "City Mall",
  "Northern Hashemi",
  "alhussain Mountain",
  "Umm Uthaina Market",
  "Immortal",
  "The Middle East",
  "Even though",
  "New Blue",
  "Abdullah Ghosheh St.",
  "Tabarbour",
  "Marka",
  "Abdoun",
  "Mafraq",
  "the main",
  "Jasmine suburb",
  "Taj Mall",
  "Makkah street",
  "Al-Rasifa",
  "Fuhais",
  "downtown",
  "Deir Alla",
  "Arabella Mall",
  "dead",
  "Wadi Saqra",
  "Abdali Boulevard",
  "Salt",
  "Ramtha",
  "Sweifieh",
  "Al-Jubaiha",
  "Irbid",
  "Bab Medina Mall Branch",
  "Al-Bayader",
  "Sh. Wasfi Al-Tal",
  "Blue",
  "Jabal Amman",
  "Marj Al-Hamam",
  "the free zone",
  "big companies",
];
export const genders = ["Male", "Female"];

export const family_statuses = [
  "MARRIED",
  "SINGLE",
  "DIVORCED",
  "WIDOWED",
  "SEPERATED",
  "OTHER",
  "nan",
];

export const employment_spheres =  [
  "Retired",
  "Public sect emp",
  "Private sect emp",
  "Unemployed",
  "Business owner",
  "Jab employee",
  "Interorg",
  "Self employed",
  "Resigned",
  "Private sect empbanker",
  "Prof self employed",
  "Banker",
  "Lawyer",
  "Unions",
  "Other",
  "nan",
] as const;

export const collaterals = ["No", "Yes"];
export const coBorrowers = ["No", "Yes"];
export const educations =  [
  "Diploma",
  "High diploma",
  "Secondary",
  "Bachelor",
  "Primary",
  "Master",
  "Elementary",
  "Sec cert",
  "Phd",
  "Illiterate",
  "Professor",
  "nan",
] as const;

export const productType = [
  "قرض سكني شراء من بنوك أخرى",
  "قرض شخصي مقابل تحويل راتب",
  "جدولة اولى",
  "قرض سكني شراء سكن",
  "قرض سكني -  أفراد",
  "قرض سكني سكن كريم",
  "قرض شخصي بضمان رهن عقاري",
  "قرض سكني موظفين - بفائدة تجارية",
  "قروض اخرى- أفراد",
  "قرض شخصي مقابل تامينات نقدية",
  "قرض سكني موظفين",
  "قرض شخصي - أفراد",
  "قرض شخصي - موظفين",
  "قرض عائلتي",
  "flat قرض سيارة جديدة",
  "قرض سكني بناء سكن",
  "قرض تنفيذين-مهنيين افراد",
  "flat قرض سيارة مستعملة",
  "قرض المستهلك",
  "قرض مخصوم",
  "قرض سلع معمرة",
  "قرض شراء سيارة جديدة",
  "قرض سكني موظفين/قديم",
  "قرض شراء سيارة مستعملة",
  "قرض شراء أراضي",
  "قرض التوفير الفوري",
  "قرض شراء سيارة - موظفين",
  "قرض سكني توسعة",
  "قرض الأطباء",
  "قرض شخصي - موظفين بنوك أخرى",
  "قرض اطباء الاختصاص",
  "قرض سكني مدعوم حكوميا",
  "قرض شخصي - موظفين/قديم",
  "قرض صديق البيئة- افراد",
  "قرض شراء سيارة - أفراد",
  "قرض سكني - صيانة",
];
export const productTypeEN = [
  "Personal loan against salary transfer",
  "flat new car loan",
  "My family loan",
  "Housing loan to buy a house",
  "Other loans - individuals",
  "Personal loan against cash deposits",
  "Housing loan to build housing",
  "Loan for individual executives and professionals",
  "Land purchase loan",
  "Personal loan secured by a mortgage",
  "Housing loan purchase from other banks",
  "Consumer Loan",
  "Loan to purchase a new car",
  "First schedule",
  "Used car purchase loan",
  "Durable goods loan",
  "flat used car loan",
  "Governmentally supported housing loan",
  "Environmentally friendly loan - individuals",
  "Personal Loan - Employees",
];

export const employment_levels =  [
  "OTHER",
  "EMPLOYEE",
  "SEC HEAD",
  "CORPORAL",
  "MILITARY",
  "MANAGER",
  "ADMIN EMP",
  "TEACHER",
  "CAPTAIN",
  "LANCE CORP",
  "URS ASST",
  "EXEC MGR",
  "NUR ASST",
  "GEN MGR",
  "ADMIN MGR",
  "ASST OFFR",
  "SUPERVISOR",
  "DRIVER",
  "SOLDIER",
  "POLICE",
  "CEO",
  "SARGENT",
  "WORKER",
  "GUARD",
  "SELF EMPL",
  "OFFICE MGR",
  "LIEUTENANT",
  "COLONEL",
  "SEC SUPER",
  "nan",
] as const;
