import Input from "components/input";
import { ScoringForm } from "pages/scoring";
import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react";
import { carEvaulation } from "utils/constants";
import styles from "./AutoForm.module.scss";

export interface AutoForm extends ScoringForm {
  property_value: string;
  property_age_at_end: string;
  car_evaluation_score: (typeof carEvaulation)[number] | "";
}

interface Props {
  form: AutoForm;
  setForm: Dispatch<SetStateAction<AutoForm>>;
  setFormValid: Dispatch<SetStateAction<boolean>>;
}

export const AutoForm: FunctionComponent<Props> = ({
  form,
  setForm,
  setFormValid,
}) => {
  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      property_value: "",
      property_age_at_end: "",
      car_evaluation_score: "",
    }));
  }, []);

  useEffect(() => {
    setFormValid(
      form.branch !== "" &&
        form.gender !== "" &&
        form.familyStatus !== "" &&
        form.employmentSphere !== "" &&
        form.education !== "" &&
        form.collateral !== "" &&
        form.coBorrower !== "" &&
        form.productType !== "" &&
        form.employmentLevel !== "" &&
        form.loanDuration !== "" &&
        form.age !== "" &&
        form.monthlyIncome !== "" &&
        form.workExperience !== "" &&
        form.dependents !== "" &&
        form.cbj_black_list !== "" &&
        form.internal_black_list !== "" &&
        form.inspector_legal_cases !== "" &&
        "property_value" in form &&
        form.property_value !== "" &&
        "property_age_at_end" in form &&
        form.property_age_at_end !== "" &&
        "car_evaluation_score" &&
        form.car_evaluation_score !== ""
    );
  }, [form]);

  return (
    <div className={styles.fieldList}>
      <Input
        title="Property value"
        id="property_value"
        value={form.property_value}
        type="integer"
        min={0}
        max={100000000}
        placeholder="min: 0 max: 100000000"
        onInput={(value) => setForm({ ...form, property_value: value })}
      />
      <Input
        title="Property age at loan maturity"
        id="property_age"
        value={form.property_age_at_end}
        type="integer"
        min={0}
        max={100}
        placeholder="min: 0 max: 100"
        onInput={(value) => setForm({ ...form, property_age_at_end: value })}
      />
      <Input
        title="Car evaluation score"
        id="car_evaluation_score"
        value={form.car_evaluation_score}
        type="select"
        options={carEvaulation}
        onSelect={(option) =>
          setForm((prev) => ({
            ...prev,
            car_evaluation_score: option as (typeof carEvaulation)[number],
          }))
        }
      />
    </div>
  );
};
