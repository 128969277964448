import classNames from "classnames";
import Icon from "icons";
import { useOCR } from "layouts/application";
import { useEffect, useState } from "react";
import { useSelector } from "store/hooks";
import { calculateRisk, RiskDivision } from "utils/calculateRisk";
import styles from "./result-box.module.scss";

const ResultBox = () => {
  const [riskDecision, setRiskDecision] = useState<{
    result: "Rejected" | "Approved" | "";
    text: RiskDivision | "";
    value: number;
  }>({
    result: "",
    text: "",
    value: 0,
  });

  const contextApplication = useSelector((state) => state.application);
  const { ocrPending } = useOCR();

  const ocrStyles = classNames(styles.value, {
    [styles.green]: contextApplication?.data?.ocr_log && !ocrPending,
    [styles.red]: !contextApplication?.data?.ocr_log && !ocrPending,
    [styles.yellow]: ocrPending && !contextApplication?.data?.ocr_log,
  });

  const riskFactorsStyles = classNames(styles.value, styles.risk, {
    [styles.red]: Number(contextApplication?.data?.risks?.total_risk) >= 50,
    [styles.green]: Number(contextApplication?.data?.risks?.total_risk) < 50,
  });

  const scoringStyles = classNames(styles.value, {
    [styles.green]: contextApplication?.data?.is_approved === true,
    [styles.red]: contextApplication?.data?.is_approved === false,
  });

  useEffect(() => {
    if (!contextApplication?.data?.risks?.total_risk) return;

    setRiskDecision(calculateRisk(contextApplication?.data?.risks?.total_risk));
  }, [contextApplication?.data?.risks?.total_risk]);

  return (
    <div className={styles.resultBox}>
      <div className={styles.resultBox_title}>Application status</div>
      {contextApplication.data?.is_approved !== null && (
        <div className={styles.finalResult}>
          <Icon
            size={120}
            name={
              contextApplication.data?.is_approved
                ? "tickCircle"
                : "closeFilled"
            }
            className={classNames(
              contextApplication.data?.is_approved ? styles.green : styles.red
            )}
          />
          <div
            className={classNames(
              styles.finalResultText,
              contextApplication.data?.is_approved ? styles.green : styles.red
            )}
          >
            {contextApplication.data?.is_approved ? "Approved" : "Rejected"}
          </div>
        </div>
      )}
      <div className={styles.resultBox_resultText}>
        <div className={styles.item}>
          <div className={styles.key}>OCR</div>
          <div className={ocrStyles}>
            {ocrPending
              ? "Pending..."
              : contextApplication?.data?.ocr_log
              ? "File provided"
              : "File not provided"}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Risk factors</div>
          <div className={riskFactorsStyles}>
            <span>{`${riskDecision.text} (${riskDecision.value}%)`}</span>
            <span>{riskDecision.result}</span>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.key}>Scoring</div>
          <div className={scoringStyles}>
            {contextApplication?.data?.is_approved === null
              ? "No decision"
              : contextApplication.data?.is_approved
              ? `Approved (${contextApplication?.data?.scoring_log?.prediction}%)`
              : `Rejected (${contextApplication?.data?.scoring_log?.prediction}%)`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultBox;
