import Input from "components/input";
import { ScoringForm } from "pages/scoring";
import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react";
import { yesOrNo } from "utils/constants";
import styles from "./LandForm.module.scss";

export interface LandForm extends ScoringForm {
  property_value: string;
  property_outside_regulations: (typeof yesOrNo)[number] | "";
}

interface Props {
  form: LandForm;
  setForm: Dispatch<SetStateAction<LandForm>>;
  setFormValid: Dispatch<SetStateAction<boolean>>;
}

export const LandForm: FunctionComponent<Props> = ({
  form,
  setForm,
  setFormValid,
}) => {
  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      property_value: "",
      property_outside_regulations: "",
    }));
  }, []);

  useEffect(() => {
    setFormValid(
      form.branch !== "" &&
        form.gender !== "" &&
        form.familyStatus !== "" &&
        form.employmentSphere !== "" &&
        form.education !== "" &&
        form.collateral !== "" &&
        form.coBorrower !== "" &&
        form.productType !== "" &&
        form.employmentLevel !== "" &&
        form.loanDuration !== "" &&
        form.age !== "" &&
        form.monthlyIncome !== "" &&
        form.workExperience !== "" &&
        form.dependents !== "" &&
        form.cbj_black_list !== "" &&
        form.internal_black_list !== "" &&
        form.inspector_legal_cases !== "" &&
        "property_value" in form &&
        form.property_value !== "" &&
        "property_outside_regulations" in form &&
        form.property_outside_regulations !== ""
    );
  }, [form]);

  return (
    <div className={styles.fieldList}>
      <Input
        title="Property value"
        id="property_value"
        value={form.property_value}
        type="integer"
        min={0}
        max={100000000}
        placeholder="min: 0 max: 100000000"
        onInput={(value) => setForm({ ...form, property_value: value })}
      />
      <Input
        title="Property outside boundaries of regulation"
        id="property_outside"
        value={form.property_outside_regulations}
        type="select"
        options={yesOrNo}
        onSelect={(option) =>
          setForm({
            ...form,
            property_outside_regulations: option as (typeof yesOrNo)[number],
          })
        }
      />
    </div>
  );
};
