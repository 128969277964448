import { FunctionComponent, ReactElement } from "react";
import styles from "./ItemContainer.module.scss";

interface Props {
  title: string;
  children: ReactElement;
}

export const ItemContainer: FunctionComponent<Props> = ({
  title,
  children,
}) => {
  return (
    <div className={styles.creditDataBox}>
      <div className={styles.title}>{title}</div>
      <div className={styles.list}>{children}</div>
    </div>
  );
};
