import {
  Document,
  Font,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Button from "components/button";
// import { useOCR } from "layouts/application";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "store/hooks";
import scoringStyles from "../scoring/scoring.module.scss";
// import ArabicFont from "./Amiri-Bold.ttf";
import Loading from "components/loading";
import ResultBox from "components/result-box";
import { useOCR } from "layouts/application";
import styles from "./ocr.module.scss";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: "medium",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: "bold",
    },
  ],
});

// Font.register({
//   family: "Amiri",
//   fonts: [
//     {
//       src: ArabicFont,
//       fontWeight: "light",
//     },
//   ],
// });

export const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "10px",
    fontWeight: "medium",
  },
  arabic: {
    fontFamily: "Amiri",
    fontWeight: "light",
  },
  title: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "2px",
    marginTop: "8px",
  },
  section: {
    marginBottom: "8px",
    marginTop: "8px",
  },
  heading: {
    fontSize: "22px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  heading2: {
    fontSize: "18px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  heading3: {
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
  },
  text: {
    fontSize: "13px",
  },
  table: {
    backgroundColor: "#fff",
    marginTop: "12px",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  th: {
    border: "1px solid #000",
    width: "100%",
    padding: "4px 10px",
    fontSize: "14px",
    fontWeight: "bold",
  },
  result: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  bottom: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rejected: {
    color: "red",
  },
  approved: {
    color: "green",
  },
  review: {
    color: "orange",
  },
});

const fieldCheck = (check: boolean | "-") => {
  if (check === true) return "pass";
  if (check === false) return "review";
  if (check === null) return "reject";
  if (check === "-") return "-";
};

const OCR = () => {
  const contextApplication = useSelector((state) => state.application);

  const [fileCRIF, setFileCRIF] = useState<File | null>();
  const { handleRunOCR, ocrPending } = useOCR();

  const [formValid, setFormValid] = useState(false);

  const handleFileChangeCRIF = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) setFileCRIF(event.target.files[0]);
  };

  useEffect(() => {
    setFormValid(!!fileCRIF);
  }, [fileCRIF]);

  return (
    <div className={styles.ocr}>
      <div className={scoringStyles.section}>
        {!ocrPending && contextApplication.data?.ocr_log !== null && (
          <div className={styles.pdf}>
            <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
              <OCRPdf application={contextApplication.data} />
            </PDFViewer>
          </div>
        )}
        {!ocrPending && contextApplication.data?.ocr_log === null && (
          <>
            <div className={styles.inputBox}>
              <label className={styles.fileInput}>
                <div className={styles.fileInputTitle}>CRIF:</div>
                {fileCRIF?.name ?? (
                  <div className={styles.fileInputDescription}>
                    Upload <span className={styles.bold}>.pdf</span> document
                  </div>
                )}
                <input
                  accept="application/pdf"
                  type="file"
                  onChange={handleFileChangeCRIF}
                />
              </label>
              {formValid && (
                <div className={styles.footerBox}>
                  <div className={styles.buttonGroup}>
                    <Button onClick={() => handleRunOCR(fileCRIF!)}>
                      Run OCR
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {ocrPending && (
          <div
            style={{
              width: "100%",
              background: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading />
          </div>
        )}
      </div>
      <div className={styles.result}>
        <div className={styles.resultBox}>
          <ResultBox />
        </div>
      </div>
    </div>
  );
};

const OCRPdf = ({ application }: { application: any }) => {
  return (
    <Document>
      {/* OCR */}
      {application?.ocr_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>OCR report</Text>
            <View style={pdfStyles.section}>
              <Text style={pdfStyles.text}>Application: {application?.id}</Text>
              <View style={pdfStyles.table}>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.th}>
                    <Text>Max days past due</Text>
                  </View>
                  <View style={pdfStyles.th}>
                    <Text>
                      {String(application?.ocr_log?.max_days_past_due)}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.th}>
                    <Text>Max days over due amount</Text>
                  </View>
                  <View style={pdfStyles.th}>
                    <Text>
                      {String(application?.ocr_log?.max_over_due_amount)}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.th}>
                    <Text>Return cheques</Text>
                  </View>
                  <View style={pdfStyles.th}>
                    <Text>{String(application?.ocr_log?.return_cheques)}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.th}>
                    <Text>Total number of late installments</Text>
                  </View>
                  <View style={pdfStyles.th}>
                    <Text>
                      {String(
                        application?.ocr_log?.total_number_of_late_installments
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.th}>
                    <Text>Write off loans</Text>
                  </View>
                  <View style={pdfStyles.th}>
                    <Text>{String(application?.ocr_log?.write_off_loans)}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default OCR;
