import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "components/button";
import Input from "components/input";
import request from "request";
import { productEnum } from "utils/constants";
import styles from "./create-modal.module.scss";

const Modal = ({ onClose }: { onClose: () => void }) => {
  const [customerID, setcustomerID] = useState("");
  const [applicationID, setapplicationID] = useState("");
  const [sum, setSum] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [product, setProduct] = useState("");
  const [pending, setPending] = useState(false);
  const [validError, setValidError] = useState<{
    name: "sum" | "interestRate" | "customerId" | "applicationId" | "product";
    value: string;
  } | null>(null);

  const navigate = useNavigate();

  const handleCreate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (customerID.trim().length === 0) {
      return setValidError({
        name: "customerId",
        value: "Customer ID is required",
      });
    } else if (customerID.trim().length < 7) {
      return setValidError({
        name: "customerId",
        value: "Customer ID must be numeric string with 7 digits",
      });
    } else if (applicationID.trim().length === 0) {
      return setValidError({
        name: "applicationId",
        value: "Application ID is required",
      });
    } else if (applicationID.trim().length < 12) {
      return setValidError({
        name: "applicationId",
        value: "Application ID must be numeric string with 12 digits",
      });
    } else if (sum.trim().length === 0) {
      return setValidError({
        name: "sum",
        value: "Amount is required",
      });
    } else if (Number(sum) > 500000) {
      return setValidError({
        name: "sum",
        value: "Maximum amount: 500000 JOD",
      });
    } else if (interestRate.trim().length === 0) {
      return setValidError({
        name: "interestRate",
        value: "Interest rate is required",
      });
    } else if (product.trim().length === 0) {
      return setValidError({
        name: "product",
        value: "Product is required",
      });
    } else setValidError(null);
    setPending(true);
    request
      .post("/applications", {
        customerId: customerID,
        applicationId: applicationID,
        loanAmount: sum,
        interestRate: interestRate,
        product: product,
      })
      .then((res) => res.data)
      .then((data) => {
        navigate(`application/${data.id}/ocr`);
      })
      .finally(() => setPending(false));
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
        onSubmit={handleCreate}
      >
        <div className={styles.title}>New application</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="Customer ID"
              type="number"
              value={customerID}
              onInput={(value) => setcustomerID(value)}
              maxLength={7}
              placeholder="1234567"
            />
            {validError?.name === "customerId" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Application ID"
              type="number"
              value={applicationID}
              onInput={(value) => setapplicationID(value)}
              maxLength={12}
              placeholder="000123456789"
            />
            {validError?.name === "applicationId" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Amount"
              type="integer"
              value={sum}
              onInput={(value) => setSum(value)}
              step={100}
              min={100}
              max={500000}
              unit="JOD"
              placeholder={`min: ${100}  max: ${500000}`}
            />
            {validError?.name === "sum" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Interest rate"
              type="float"
              value={interestRate}
              onInput={(value) => setInterestRate(value)}
              step={0.1}
              min={0}
              max={33.4}
              placeholder={`min: 0  max: ${33.4}`}
              unit="%"
            />
            {validError?.name === "interestRate" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Product"
              type="select"
              value={product}
              options={productEnum}
              onSelect={(option) => setProduct(option)}
              placeholder="Select product"
              hasFilterText
            />
            {validError?.name === "product" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Create
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
