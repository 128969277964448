import Input from "components/input";
import { ScoringForm } from "pages/scoring";
import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react";
import { yesOrNo } from "utils/constants";
import styles from "./PersonalForm.module.scss";

export interface PersonalForm extends ScoringForm {
  target_market_list: string;
  length_of_service: string;
}

interface Props {
  form: PersonalForm;
  setForm: Dispatch<SetStateAction<PersonalForm>>;
  setFormValid: Dispatch<SetStateAction<boolean>>;
}

export const PersonalForm: FunctionComponent<Props> = ({
  form,
  setForm,
  setFormValid,
}) => {
  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      target_market_list: "",
      length_of_service: "",
    }));
  }, []);

  useEffect(() => {
    setFormValid(
      form.branch !== "" &&
        form.gender !== "" &&
        form.familyStatus !== "" &&
        form.employmentSphere !== "" &&
        form.education !== "" &&
        form.collateral !== "" &&
        form.coBorrower !== "" &&
        form.productType !== "" &&
        form.employmentLevel !== "" &&
        form.loanDuration !== "" &&
        form.age !== "" &&
        form.monthlyIncome !== "" &&
        form.workExperience !== "" &&
        form.dependents !== "" &&
        form.cbj_black_list !== "" &&
        form.internal_black_list !== "" &&
        form.inspector_legal_cases !== "" &&
        form.target_market_list !== "" &&
        form.length_of_service !== ""
    );
  }, [form]);

  return (
    <div className={styles.fieldList}>
      <Input
        title="Target market list"
        id="target_market_list"
        value={form.target_market_list}
        type="select"
        options={yesOrNo}
        onSelect={(option) =>
          setForm((prev) => ({ ...prev, target_market_list: option }))
        }
      />
      <Input
        title="Length of service (in months)"
        id="credit_card_limit"
        value={form.length_of_service}
        type="integer"
        min={0}
        max={100000000}
        placeholder="min: 0 max: 100000000"
        onInput={(value) => setForm({ ...form, length_of_service: value })}
      />
    </div>
  );
};
